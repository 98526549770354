// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
//import "channels"
import 'chart.js/dist/chart.min';
import * as bootstrap from 'bootstrap';
//import * as d3 from "d3";
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
//window.d3 = d3;

window.jQuery = $;
window.$ = $;

    var toastElList = [].slice.call(document.querySelectorAll('.toast'))
    var toastList = toastElList.map(function(toastEl) {
    return new bootstrap.Toast(toastEl).show() // No need for options; use the default options
    })


Rails.start()
//Turbolinks.start()
///ActiveStorage.start()
